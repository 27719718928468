/* eslint-disable no-script-url */
// Blog.js

import React, { useEffect, useState } from "react";
import "./blog.css";
import {
  app,
  getFirestore,
  getStorage,
  ref,
  collection,
  addDoc,
  uploadBytesResumable,
  getDownloadURL,
} from "../../../../FireBase/firebase";
import { deleteBlogPost, getBlogPosts } from "../../../../FireBase/adminServices";
import { MdDelete } from "react-icons/md";

const Blog = () => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];
  const [formData, setFormData] = useState({
    authorLogo: "https://firebasestorage.googleapis.com/v0/b/branchselector-493c2.appspot.com/o/article_pictures%2Fapg.jpg?alt=media&token=34c28df4-4894-4636-8bc0-597cdb593cfd",
    authorName: "Dr. Ananth Prabhu G",
    date: formattedDate,
    desc: "",
    href: "https://www.facebook.com/educatorananth/",
    img: "",
    title: "",
  });

  const [blogData, setBlogData] = useState(null);

    const fetchData = async () => {
    try {
      const data = await getBlogPosts();
      console.log(data);
      setBlogData(data);
    } catch (error) {
      console.log("Error Fetching data :", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);




  const [showModal, setShowModal] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImageFile(file);

    // Generate a preview of the selected image
    const reader = new FileReader();
    reader.onload = (event) => {
      setPreviewURL(event.target.result);
    };
    reader.readAsDataURL(file);
  };

const handleDeleteBlog = async (postId) => {
  const confirmation = window.confirm("Are you sure you want to delete this blog post?");
  if (confirmation) {
    await deleteBlogPost(postId);
    fetchData()
    // Optionally update the UI after deletion

  }
};
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (imageFile) {
      const storage = getStorage(app);
      const storageRef = ref(storage, `/article_pictures/${imageFile.name}`);
  
      // Create a promise for the image upload and URL assignment
      const uploadAndAssignURLPromise = new Promise(async (resolve, reject) => {
        try {
          // Upload image to storage
          await uploadBytesResumable(storageRef, imageFile);
  
          // Get image URL
          const imageURL = await getDownloadURL(storageRef);
  
          // Set formData with the imageURL
          setFormData({
            ...formData,
            img: imageURL,
          });
  
          // Resolve the promise with the imageURL
          resolve(imageURL);
        } catch (error) {
          reject(error);
        }
      });
  
      // Wait for the promise to resolve before pushing data to Firestore
      try {
        const imageURL = await uploadAndAssignURLPromise;
        const db = getFirestore(app);
        const docRef = await addDoc(collection(db, "blog-posts"), {
          ...formData,
          img: imageURL, // Ensure img is set with the URL obtained from storage
        });
        console.log("Document written with ID: ", docRef.id);
      } catch (error) {
        console.error("Error uploading image or adding document: ", error);
      }
      fetchData()
    }
  
    // Reset form data and close modal
    setFormData({
      authorLogo:
        "https://sahyadri.edu.in/images/profile/WhatsApp%20Image%202021-06-04%20at%2012.47.29%20PM.jpeg",
      authorName: "Dr. Ananth Prabhu G",
      date: formattedDate,
      desc: "",
      href: "https://www.facebook.com/educatorananth/",
      img: "",
      title: "",
    });
    setShowModal(false);
  };
  

  const handleCancel = () => {
    setFormData({
      authorLogo: "https://sahyadri.edu.in/images/profile/WhatsApp%20Image%202021-06-04%20at%2012.47.29%20PM.jpeg",
      authorName: "Dr. Ananth Prabhu G",
      date: formattedDate,
      desc: "",
      href: "https://www.facebook.com/educatorananth/",
      img: "",
      title: "",
    });
    setShowModal(false);
  };

  return (
    <main id="test" className="a_main-container">
      <button
        onClick={() => setShowModal(true)}
        className="createPostbtn"
        id="newPostBtn"
      >
        Create New Post
      </button>
      {blogData &&
      <div className="a_table">
        <table id="users">
          <thead className="tablehead">
            <tr>
              <th>No.</th>
              <th>Date</th>
              <th>Title</th>
              <th>Desc</th>
            </tr>
          </thead>
          <tbody>
            {blogData.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.date.toString()}</td>
                  <td>{item.title}</td>
                  <td>{item.desc.substring(0, 50) + '...'}</td>
                  <td>
                    <MdDelete
                      className="action"
                      onClick={() => handleDeleteBlog(item.id)} // Pass the voucher code to the delete function
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      }
      {showModal && (
        <div className="modal-overlay">
          <div className="newPost-modal">
            <form onSubmit={handleSubmit} className="BlogEditor">
              <label htmlFor="authorLogo">Author Logo URL:</label>
              <input
                type="text"
                id="authorLogo"
                name="authorLogo"
                value={formData.authorLogo}
                onChange={handleChange}
                required
              />

              <label htmlFor="authorName">Author Name:</label>
              <input
                type="text"
                id="authorName"
                name="authorName"
                value={formData.authorName}
                onChange={handleChange}
                required
              />

              <label htmlFor="date">Date:</label>
              <input
                type="date"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                required
              />
              
              <label htmlFor="title">Title:</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
              />

              <label htmlFor="desc">Description:</label>
              <textarea
                id="desc"
                name="desc"
                value={formData.desc}
                onChange={handleChange}
                required
              ></textarea>

              <label htmlFor="href">Link:</label>
              <input
                type="text"
                id="href"
                name="href"
                value={formData.href}
                onChange={handleChange}
                required
              />

              <label htmlFor="img">Image Upload:</label>
              <input
                type="file"
                id="img"
                name="img"
                accept="image/*"
                onChange={handleImageUpload}
                required
              />
              
              {/* Add image preview */}
              {previewURL && (
                <img
                  src={previewURL}
                  alt="Preview"
                  style={{ width: "100px", height: "100px", objectFit: "cover" }}
                />
              )}


              <button type="submit">Create</button>
              <button
                type="button"
                className="createPostbtn"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </main>
  );
}

export default Blog;
